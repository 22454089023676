import React from "react"

export default function Quotes() {
  return (
    <svg
      width="103px"
      height="85px"
      viewBox="0 0 103 85"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        opacity="0.701171875"
      >
        <path
          d="M33.3161627,38.5219308 C39.5662765,40.2803527 44.7364877,44.2530836 47.8757395,49.7034441 C51.0488406,55.2157284 51.8361103,61.6034868 50.094508,67.6944382 C47.1790986,77.8830356 37.5953724,85 26.787611,85 C26.7515778,85 26.7166366,84.9989323 26.6806034,84.9978647 C17.9212721,84.6636898 11.1142823,81.3187379 6.44853526,75.054827 C-1.88931743,63.8605019 -0.896767918,46.0755646 2.48816442,34.2459869 C8.25456045,14.0823222 21.9198599,0 35.7183728,0 C36.575525,0 37.4403206,0.0576531766 38.2887375,0.170824227 C39.0694558,0.275454066 39.7660967,0.705717587 40.1995864,1.35057904 C40.6330762,1.9954405 40.7586462,2.79084081 40.546815,3.53392619 L38.0343217,12.3206974 C37.7438726,13.3338986 36.8714336,14.0865928 35.8068178,14.2414023 C24.3111508,15.9122767 18.5403871,30.7494285 16.1381771,39.348293 C18.629924,38.2945211 21.8434259,37.433994 25.6946054,37.433994 C28.1885362,37.433994 30.7523495,37.8001985 33.3161627,38.5219308 Z M99.8753525,49.7038875 C103.047401,55.2150349 103.835773,61.6037807 102.096333,67.6946555 C99.1808868,77.883125 89.5959493,85 78.7891452,85 C78.7520196,85 78.717078,84.9989324 78.6810444,84.9978647 C69.9226958,84.6647617 63.1145293,81.3187841 58.4498161,75.0560196 C50.1107676,63.8596998 51.1033294,46.0749859 54.487212,34.2455567 C60.2547718,14.0821453 73.9202415,0 87.7189263,0 C88.5771811,0 89.4419875,0.0576524524 90.2893231,0.170822081 C91.071143,0.275450606 91.7677926,0.705708723 92.2001958,1.35056208 C92.6336909,1.99541544 92.7592625,2.79080575 92.5474286,3.53388181 L90.034904,12.3205426 C89.7433594,13.3337311 88.8720015,14.0864159 87.8073724,14.2412234 C76.3104703,15.9120769 70.5396347,30.7490423 68.1373947,39.3477988 C70.6291727,38.2940401 73.8438066,37.4335238 77.695034,37.4335238 C80.187904,37.4335238 82.7517492,37.7997237 85.3155944,38.521447 C91.565786,40.2798468 96.7360616,44.2525278 99.8753525,49.7038875 Z"
          fill="#EFEFEF"
        ></path>
      </g>
    </svg>
  )
}
