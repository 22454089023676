import React from "react"
import Slider from "react-slick"
import { motion } from "framer-motion"

import { containerAnimation } from "../../shared/utils/motionAnimations"
import SlickDots from "../../shared/components/Slick/SlickDots/SlickDots"
import SlickArrow from "../../shared/components/Slick/SlickArrow/SlickArrow"

import styles from "./hero.module.scss"
import "./hero-slick.scss"
import "./hero-gifs.scss"

export default function Hero({ slides, backgroundColor, textColor }) {
  const settings = {
    customPaging: index => (
      <div>
        <SlickDots index={index} color={textColor} />
      </div>
    ),
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SlickArrow outlineColor={textColor} />,
    prevArrow: <SlickArrow prevArrow={true} outlineColor={textColor} />,
  }

  return (
    <motion.section
      className={`hero ${styles.hero}`}
      style={{ backgroundColor: backgroundColor }}
      variants={containerAnimation}
      initial="initial"
      animate="animate"
    >
      <div className={styles.container}>
        <Slider {...settings}>
          {slides.map((slideItem, index) => (
            <div className={styles.sliderContainer} key={index}>
              <div
                className={styles.descriptionContainer}
                style={{ color: textColor }}
              >
                <h3 className={styles.title}>{slideItem.title}</h3>
                <div
                  className={styles.border}
                  style={{ backgroundColor: textColor }}
                ></div>
                <div
                  className={styles.description}
                  dangerouslySetInnerHTML={{
                    __html: slideItem.description.html,
                  }}
                />
              </div>
              {slideItem.desktop_image.url ? (
                <div className={styles.imageContainer}>
                  <img
                    src={slideItem.mobile_image.url}
                    alt={slideItem.title}
                    className={styles.mobileImage}
                  />
                  <img
                    src={slideItem.desktop_image.url}
                    alt={slideItem.title}
                    className={styles.desktopImage}
                  />
                  {slideItem.gif_class ? (
                    <img
                      src={slideItem.gif.url}
                      className={`${slideItem.gif_class} gif`}
                      alt={slideItem.title}
                    />
                  ) : null}
                </div>
              ) : null}
            </div>
          ))}
        </Slider>
      </div>
    </motion.section>
  )
}
