import React from "react"
import Arrow from "../../Icons/Arrow"

import styles from "./slick-arrow.module.css"

export default function SlickArrow({ onClick, prevArrow, outlineColor }) {
  return (
    <button
      className={`${styles.arrow} ${prevArrow ? styles.prev : ""}`}
      onClick={onClick}
    >
      <Arrow backward={prevArrow} outlineColor={outlineColor} />
    </button>
  )
}
