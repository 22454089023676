import React from "react"

export default function Arrow({ backward, outlineColor }) {
  return (
    <svg
      width="32px"
      height="32px"
      viewBox="0 0 32 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        {backward ? (
          <g
            transform="translate(-1481.000000, -662.000000)"
            fillRule="nonzero"
          >
            <g transform="translate(1481.000000, 662.000000)">
              <path
                d="M0,0 L0,32 L32,32 L32,0 L0,0 Z M30.0206186,30.0206186 L1.97938144,30.0206186 L1.97938144,1.97938144 L30.0206186,1.97938144 L30.0206186,30.0206186 Z"
                fill={outlineColor || "#000000"}
              ></path>
              <path
                d="M13.0366845,15.9996856 L19.8738846,10.5802902 C20.0420385,10.4470058 20.0420385,10.2332478 19.8738846,10.0999633 C19.7057307,9.96667889 19.43605,9.96667889 19.2678961,10.0999633 L12.1261154,15.7607796 C11.9579615,15.894064 11.9579615,16.1078221 12.1261154,16.2411065 L19.2678961,21.899408 C19.3503867,21.9647928 19.4614317,22 19.569304,22 C19.6771763,22 19.7882213,21.9673076 19.8707119,21.899408 C20.0388658,21.7661235 20.0388658,21.5523655 19.8707119,21.419081 L13.0366845,15.9996856 Z"
                stroke={outlineColor || "#000000"}
                fill="#FFFFFF"
                transform="translate(16.000000, 16.000000) rotate(-360.000000) translate(-16.000000, -16.000000) "
              ></path>
            </g>
          </g>
        ) : (
          <g
            transform="translate(-1529.000000, -662.000000)"
            fillRule="nonzero"
          >
            <g transform="translate(1529.000000, 662.000000)">
              <path
                d="M0,0 L0,32 L32,32 L32,0 L0,0 Z M30.0206186,30.0206186 L1.97938144,30.0206186 L1.97938144,1.97938144 L30.0206186,1.97938144 L30.0206186,30.0206186 Z"
                fill={outlineColor || "#000000"}
              ></path>
              <path
                d="M13.0366845,15.9996856 L19.8738846,10.5802902 C20.0420385,10.4470058 20.0420385,10.2332478 19.8738846,10.0999633 C19.7057307,9.96667889 19.43605,9.96667889 19.2678961,10.0999633 L12.1261154,15.7607796 C11.9579615,15.894064 11.9579615,16.1078221 12.1261154,16.2411065 L19.2678961,21.899408 C19.3503867,21.9647928 19.4614317,22 19.569304,22 C19.6771763,22 19.7882213,21.9673076 19.8707119,21.899408 C20.0388658,21.7661235 20.0388658,21.5523655 19.8707119,21.419081 L13.0366845,15.9996856 Z"
                stroke={outlineColor || "#000000"}
                fill="#FFFFFF"
                transform="translate(16.000000, 16.000000) scale(-1, 1) rotate(-360.000000) translate(-16.000000, -16.000000) "
              ></path>
            </g>
          </g>
        )}
      </g>
    </svg>
  )
}
