export default function getRandomProjects(Projects, currentProject) {
  const randomProjects = Projects.map(({ node }) => ({
    id: node.id,
    uid: node.uid,
    title: node.data.title,
    description: node.data.description,
    textColor: node.data.text_color,
    backgroundColor: node.data.background_color,
  })).filter(item => item.uid !== currentProject)

  for (let i = randomProjects.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1))
    ;[randomProjects[i], randomProjects[j]] = [
      randomProjects[j],
      randomProjects[i],
    ]
  }
  return randomProjects
}
