import React from "react"
import styles from "./slick-dots.module.css"

export default function SlickDots({ index, color }) {
  return (
    <button className={styles.dot} style={{ border: `2px solid ${color}` }}>
      <span
        className={styles.activeSlide}
        style={{
          backgroundColor: color,
        }}
      />
      Go to Project {index + 1}
    </button>
  )
}
