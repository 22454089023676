import React from "react"
import classnames from "classnames"

import Quotes from "../../shared/components/Icons/Quotes"
import styles from "./content-container.module.scss"

const contentTypes = {
  DEFAULT: "PrismicCaseStudyBodyDefaultTextBlock",
  PAIN_POINTS: "PrismicCaseStudyBodyPainPoints",
  PERSONAS: "PrismicCaseStudyBodyPersona",
  FULL_SCREEN_IMAGE: "PrismicCaseStudyBodyFullScreenImage",
}

export default function ContentContainer({ data, type, imageType }) {
  let contentData

  switch (type) {
    case contentTypes.DEFAULT:
      contentData = (
        <div
          className={`${styles.container} ${styles[imageType]}`}
          dangerouslySetInnerHTML={{ __html: data.content.html }}
        />
      )
      break
    case contentTypes.PAIN_POINTS:
      contentData = (
        <div className={`${styles.container}`}>
          <div
            className={styles.left}
            dangerouslySetInnerHTML={{ __html: data.pain_points_left.html }}
          />
          <div className={styles.right}>
            <Quotes />
            <div
              dangerouslySetInnerHTML={{ __html: data.pain_points_right.html }}
            />
            <Quotes />
          </div>
        </div>
      )
      break
    case contentTypes.FULL_SCREEN_IMAGE:
      contentData = (
        <img src={data.fullscreen_image.url} atl={data.fullscreen_image.alt} />
      )
      break
    case contentTypes.PERSONAS:
      contentData = (
        <div
          className={`${styles.container}`}
          dangerouslySetInnerHTML={{ __html: data.content.html }}
        />
      )
      break
    default:
      break
  }

  return (
    <section
      className={classnames(styles.contentContainer, {
        [styles.default]: type === contentTypes.DEFAULT,
        [styles.painPoints]: type === contentTypes.PAIN_POINTS,
        [styles.personas]: type === contentTypes.PERSONAS,
        [styles.fullscreenImage]: type === contentTypes.FULL_SCREEN_IMAGE,
      })}
    >
      {contentData}
    </section>
  )
}
