import React from "react"
import { Link } from "gatsby"
import { StaticQuery, graphql } from "gatsby"

import getRandomProjects from "./getRandomProjects"
import styles from "./read-more.module.scss"

export default function ReadMore({ currentProject }) {
  return (
    <StaticQuery
      query={graphql`
        query AllProjectsQuery {
          projects: allPrismicCaseStudy(
            sort: { fields: [last_publication_date], order: DESC }
          ) {
            edges {
              node {
                id
                uid
                data {
                  title
                  description
                }
              }
            }
          }
        }
      `}
      index
      render={({ projects }) => {
        const randomProjects = getRandomProjects(projects.edges, currentProject)
        return (
          <section className={styles.readMore}>
            <div className={styles.container}>
              <h5 className={styles.title}>Wanna see more?</h5>
            </div>
            <div className={styles.readMoreOptions}>
              <div className={styles.container}>
                <div className={styles.stroke} />
                {randomProjects.map(({ id, uid, title, description }) => (
                  <Link key={id} to={uid} className={styles.link}>
                    <h4 className={styles.projectTitle}>{title}</h4>
                    <div className={styles.border} />
                    <p className={styles.description}>{description}</p>
                  </Link>
                ))}
              </div>
            </div>
          </section>
        )
      }}
    />
  )
}
