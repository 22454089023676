import React from "react"
import styles from "./introduction.module.scss"

export default function Introduction({ metadata }) {
  return (
    <section className={styles.introduction}>
      <div className={`reading-container ${styles.container}`}>
        <h3 className={styles.title}>Introduction</h3>
        {metadata.map((item, index) => (
          <p key={index} className={styles.text}>
            {item.text}
          </p>
        ))}
      </div>
    </section>
  )
}
