import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Layout from "../shared/components/Layout/Layout"
import Seo from "../Seo/Seo"
import Hero from "../ProjectPage/Hero/Hero"
import About from "../ProjectPage/About/About"
import Introduction from "../ProjectPage/Introduction/Introduction"
import ContentContainer from "../ProjectPage/ContentContainer/ContentContainer"
import ReadMore from "../ProjectPage/ReadMore/ReadMore"

CaseTemplate.propTypes = {
  data: PropTypes.shape({
    prismicCaseStudy: PropTypes.object.isRequired,
  }).isRequired,
}

export default function CaseTemplate({ data }) {
  const { prismicCaseStudy } = data

  return (
    <Layout isCase>
      <Seo
        keywords={[
          `ux`,
          `ui`,
          `design`,
          `redesign`,
          `mayse falsiroli`,
          `case study`,
        ]}
      />
      <Hero
        slides={prismicCaseStudy.data.carousel}
        backgroundColor={prismicCaseStudy.data.background_color}
        textColor={prismicCaseStudy.data.text_color}
      />
      <About
        left={prismicCaseStudy.data.about_left}
        right={prismicCaseStudy.data.about_right}
      />
      <Introduction metadata={prismicCaseStudy.data.introduction.raw} />
      {prismicCaseStudy.data.body.map(item => (
        <ContentContainer
          key={item.id}
          data={item.primary}
          type={item["__typename"]}
          imageType={item.primary.image_type}
        />
      ))}
      <ReadMore currentProject={prismicCaseStudy.uid} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query CaseStudy($uid: String!) {
    prismicCaseStudy(uid: { eq: $uid }) {
      uid
      data {
        published
        title
        description
        background_color
        text_color
        links_color
        carousel {
          title
          desktop_image {
            url
          }
          mobile_image {
            url
          }
          gif_class
          gif {
            url
          }
          description {
            html
          }
        }
        about_left {
          title
          description
        }
        about_right {
          title
          description {
            html
          }
        }
        introduction {
          raw {
            text
          }
        }
        body {
          ... on PrismicCaseStudyBodyDefaultTextBlock {
            id
            primary {
              image_type
              content {
                html
              }
            }
          }
          ... on PrismicCaseStudyBodyPainPoints {
            id
            primary {
              pain_points_left {
                html
              }
              pain_points_right {
                html
              }
            }
          }
          ... on PrismicCaseStudyBodyPersona {
            id
            primary {
              content {
                html
              }
            }
          }
          ... on PrismicCaseStudyBodyFullScreenImage {
            id
            primary {
              fullscreen_image {
                url
              }
            }
          }
        }
      }
    }
  }
`
