import React from "react"
import { motion } from "framer-motion"

import { containerAnimation } from "../../shared/utils/motionAnimations"

import styles from "./about.module.scss"

export default function About({ left, right }) {
  return (
    <motion.section
      className={styles.about}
      variants={containerAnimation}
      initial="initial"
      animate="animate"
    >
      <div className={styles.left}>
        {left.map((item, index) => (
          <div className={styles.textContainer} key={index}>
            <h4 className={styles.title}>{item.title}</h4>
            <p className={styles.text}>{item.description}</p>
          </div>
        ))}
      </div>
      <div className={styles.right}>
        {right.map((item, index) => (
          <div className={styles.textContainer} key={index}>
            <h4 className={styles.title}>{item.title}</h4>
            <div dangerouslySetInnerHTML={{ __html: item.description.html }} />
          </div>
        ))}
      </div>
    </motion.section>
  )
}
